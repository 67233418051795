document.addEventListener('DOMContentLoaded', function() {
  var calendarEl = document.getElementById('calendar');
  var calendar = new FullCalendar.Calendar(calendarEl, {
    plugins: [ 'list', 'googleCalendar' ],
    defaultView: 'listTwentyDay',
    header: {
        left: false,
        center: false,
        right: false
    },
    googleCalendarApiKey: 'AIzaSyC7u6vQablolgB0CIpPytS-oCEDImFMJUw',
    events: {
    googleCalendarId: 'ltklmhsfgcfc6lv83me0dn78ac@group.calendar.google.com',
    },
    eventClick: function(info) {
        info.jsEvent.preventDefault();
    },
    views: {
        listTwentyDay: {
          type: 'list',
          duration: { days: 60 },
          buttonText: '60 day'
        }
    },
    eventRender: function(info) {
        var description = info.el.getElementsByClassName("fc-list-item-title")[0];
        // test.innerHTML += "<div>" + info.event.extendedProps.description + "</div>";
        description.innerHTML += "<div>" + info.event.extendedProps.location + "</div>";
    },
    eventTimeFormat: {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
    },
    listDayFormat: false,
    height: 'auto'
  });
  calendar.render();
});
